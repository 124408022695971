import * as _ from "lodash";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { AdminService } from "src/app/admin/admin-service/admin.service";
import {
  convertBoqLinkToProjectDetail,
  Helpers,
  IBoqLink,
} from "src/app/helpers/Helpers";
import { FileUploadComponent } from "../pdfupload/file-upload.component";
import { AlertBoxComponent } from "../alertbox/alert-box.component";
import { saveAs } from "file-saver";
import { BOQCode, BoqSection, IBOQCode, ICode, TransformType } from "../../Models/IBoqHeader";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { map, tap } from "rxjs/operators";
import { Subscription } from "rxjs";
import {
  BoqDetailDropDown,
  IInfrastructureDetail,
  InfrastructureDetail,
  IProjectSnapshotDetail,
} from "../graph/IInfrastructureDetail";
import { MatSelectChange } from "@angular/material/select";
import { IProjectDetail } from "../graph/IProjectDetail";
import { BoqDetailService } from "../../serivces/boq-detail.service";
import { IRefBoq } from "../../admin/admin-service/IProjectSummaryReport";
import {
  EBoqClassification,
  EInfrastructureClassification,
} from "../../admin/admin-service/classificationEnum";
import { ProjectService } from "src/app/serivces/project.service";
import { BoqService } from "src/app/services/boq.service";
import { BoqResponse, LineItem, Section } from "src/app/Models/boq-detail";
import { ActivatedRoute } from "@angular/router";
import { IBreadcrumbElement } from "src/app/Models/IBreadcrumbElement";

function isNewAttributeICode(x: LineItem | {}): x is LineItem {
  return !_.isEmpty(x);
}

const OPTION_RELATED_INFRASTRUCTURE_CLASSIFICATIONS = [
  EInfrastructureClassification.Option,
  EInfrastructureClassification.BaseOption,
];

const OPTION_RELATED_BOQ_CLASSIFICATIONS = [
  EBoqClassification.BaseOption,
  EBoqClassification.Option,
];

type IRefBoqBasicInfo = Pick<
  IRefBoq,
  "parentBoqNum" | "boqClassificationInd" | "boqName" | "createdDt" | "infrastructureNum" | "milestoneNum"
>;

@Component({
  selector: "app-boq-detail",
  templateUrl: "./boq-detail.component.html",
  styleUrls: ["./boq-detail.component.scss"],
})
export class BoqDetailComponent implements OnInit, OnDestroy {
  constructor(
    private adminService: AdminService,
    private boqService: BoqService,
    public help: Helpers,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private boqDetailService: BoqDetailService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute
  ) {}

  public get isAdmin(): boolean {
    return this.help.isAdmin;
  }

  refBoq: IRefBoqBasicInfo;

  public get boqHeader(): IRefBoqBasicInfo {
    return this.refBoq;
  }

  public hasError = false;
  hasValidDate: boolean;
  transformType: TransformType;
  dateNow = new Date();
  client: any;
  milestonesData = [];
  
  public get mileStone()
  {
    if(this.milestonesData && this.refBoq?.milestoneNum)
      return this.milestonesData.find(c=>c.milestoneNum == this.refBoq.milestoneNum).milestoneName;
    return "NA"
  }
  public get infrastructure(): IInfrastructureDetail {
    if (this.isProjectSnapshot) {
      return this.projectSnapshot as IInfrastructureDetail;
    }    
    return this.infrastructures.find(
      (value) => value.infrastructure_num === this.refBoq.infrastructureNum
    );
  }

  
  public get projectDetail(): IProjectDetail {
    return convertBoqLinkToProjectDetail(this.boqLink);
  }

  private get infrastructuresWithBoq(): IInfrastructureDetail[] {
    return this.infrastructures.filter((i) => i.boqNum);
  }

  //#endregion
  private projectSnapshot: IProjectSnapshotDetail;

  public get shouldDisableUploadButton(): boolean {
    return this.isProjectSnapshot || //cannot upload pdf for project snapshot
    this.boqHeader.parentBoqNum != null || !this.help.hasProjectWrite(this.boqLink.proNum)//cannot upload pdf for inactive BOQ
    
  }

  public get dropdownOptions(): BoqDetailDropDown[] {
    const infrastructureDetails = this.infrastructuresWithBoq;
    const currentInfra = this.infrastructure;

    if (!BoqDetailComponent.isInfrastructureDetailClass(currentInfra)) {
      //currentInfra is project snapshot
      return this.getDropDownForBaseInfra(infrastructureDetails);
    }

    if (
      currentInfra.infrastructureClassificationInd ===
      EInfrastructureClassification.Base
    ) {
      return this.getDropDownForBaseInfra(infrastructureDetails);
    }

    if (
      OPTION_RELATED_INFRASTRUCTURE_CLASSIFICATIONS.includes(
        currentInfra.infrastructureClassificationInd
      )
    ) {
      return infrastructureDetails.filter((i) => {
        return (
          OPTION_RELATED_INFRASTRUCTURE_CLASSIFICATIONS.includes(
            i.infrastructureClassificationInd
          ) && i.package_num === currentInfra.package_num
        );
      });
    }

    throw new Error(
      `Invalid infrastructureClassificationInd = ${currentInfra.infrastructureClassificationInd}`
    );
  }

  //#region PDF Upload
  public get editAllowed(): boolean {
    return !this.boqHeader.parentBoqNum && !this.isProjectSnapshot;

  }

  boqDetail: BoqResponse;
  boqCodes: IBOQCode[] = [];
  boqLink: IBoqLink;
  totalCost = 0;
  public infrastructures: IInfrastructureDetail[] = [];
  public newAttribute: Partial<LineItem> = {};
  private subscription: Subscription;
  sections: Array<BoqSection> = [];
  editingSectionIndex: number;
  searchString: string;
  editingCodeIndex: number | string = "praxis";
  showAllData = false;

  private static isInfrastructureDetailClass(
    i: BoqDetailDropDown
  ): i is InfrastructureDetail {
    return i instanceof InfrastructureDetail;
  }

  ngOnInit() {
    this.boqLink = JSON.parse(this.help.getitem("boqlink"));
    if(!this.boqLink)
      this.boqLink = {
        proname: '',
        clientname: '',
        infraName: '',
        infrastructureNum: undefined,
        proNum: undefined,
        clientNum: undefined,
        boqnum: undefined,
        packagename: '',
        projectStageInd: undefined,
      };
    this.boqLink.boqnum = this.activatedRoute.snapshot.params.boqId;
    this.boqLink.proNum = this.activatedRoute.snapshot.params.projectId;
    this.adminService.getMileStoneData(this.boqLink.proNum).subscribe((res: any) => {
      this.milestonesData = res.data;
    }, (err: any) => {
      console.log(err);
    }); 
 
 
    this.projectSnapshot = {
      project_name: this.boqLink.proname,
      projectNum: this.boqLink.proNum,
    } as IProjectSnapshotDetail;

    this.spinner
      .show()
      .then(() => {
        return Promise.all([
          this.getInfrastructureSummaryReport(),
          this.getBoqCodes(),
          this.getBoqBasicDetail()

        ]);
      })
      .catch((err) => {
        this.hasError = true;
        console.error(err);
      })
      .finally(() => {
        return this.spinner.hide();
      });

      this.setClient(this.boqLink.proNum);
      this.setHasValidDate(this.boqLink.proNum);      
  }

  public get breadCrumbInfrastructePage(): IBreadcrumbElement {
    return {
      name: "Infrastructe Details",
      linkUrl: `/admin/projects/${this.boqLink.proNum}/infrastucture-boq/${this.boqLink.infrastructureNum}`
    };
  }


  public shouldShowRow(code: LineItem, codeIndex: number): boolean {
    return ( this.showAllData || this.editingCodeIndex == codeIndex || (code.quantity && code.quantity > 0)) && this.shouldShowLineItem(code);
   /* return this.boqDetailService.shouldShowRow(
      code,
      this.searchString,
      this.editingCodeIndex === codeIndex,
      this.showAllData
    );*/
  }

  public shouldShowLineItem(code: LineItem): boolean
  {
    if(!this.searchString?.length)
     return true;
    return code.cd.toLowerCase().includes(this.searchString.toLowerCase()) || code.description.toLowerCase().includes(this.searchString.toLowerCase());
  }

  public shouldShowSection(section: Section, sectionIndex:number): boolean {
    if (this.showAllData) {
      return true;
    }    
    return this.editingSectionIndex == sectionIndex ||  section.lineItems.filter(c=> c.quantity > 0).length !== 0;
  }

  private getDropDownForBaseInfra(
    infrastructureDetails: IInfrastructureDetail[]
  ): BoqDetailDropDown[] {
    const temp: BoqDetailDropDown[] = infrastructureDetails.filter(
      (i) =>
        i.infrastructureClassificationInd === EInfrastructureClassification.Base
    );
    temp.push(this.projectSnapshot);
    return temp;
  }

  uploadAttachment() {
    const dialogconfig = new MatDialogConfig();
    dialogconfig.disableClose = true;
    dialogconfig.autoFocus = true;
    dialogconfig.width = "100%";
    return this.dialog.open(FileUploadComponent, {
      disableClose: true,
      data: {
        boqNum: this.boqLink.boqnum,
        clientNum: this.boqLink.clientNum,
      },
    });
  }

  private getInfrastructureSummaryReport(): Promise<unknown> {
    return this.adminService
      .getInfrastructureSummaryReport(this.boqLink.proNum)
      .pipe(
        tap((result) => {
          if (result.succeeded) {
            this.infrastructures = result.data;
          } else {
            throw new Error(
              `Fail to getInfrastructureSummaryReport for project ${this.boqLink.proNum}`
            );
          }
        })
      )
      .toPromise();
  }

  getBoqCodesOfInfrastructure(): Promise<BoqResponse> {
    return this.boqService.getInfrastructureBoq(this.boqLink.proNum, this.boqLink.boqnum).pipe(tap((res) =>{
      this.boqDetail = res;
      this.totalCost = this.boqDetail?.totalCost;
    })).toPromise();
  
  }

  //#region Infrastructure BOQ Details

  ngOnDestroy() {
    return this.subscription && this.subscription.unsubscribe();
  }

  loadBoq($event: MatSelectChange) {
    const infra = $event.value as BoqDetailDropDown;

    if (BoqDetailComponent.isInfrastructureDetailClass(infra)) {
      return this.help.navigateToBoqDetailPage(
        infra,
        this.projectDetail,
        infra.boqLink
      );
    } else {
      return this.help.navigateToProjectSnapshotPage(
        infra.project_name,
        this.projectDetail
      );
    }
  }

  //#endregion

  //#region Open Edit Field

  editData(data: LineItem, codeIndex: number, sectionIndex: number) {
    if (!this.hasValidDate) return;
    this.help.setitem("boqolddata", JSON.stringify(data));
    this.newAttribute = data;
    this.editingCodeIndex = codeIndex;
    this.editingSectionIndex = sectionIndex;
    console.log(this.editingCodeIndex);
  }

  /*Why extract to method? Because previously there is a bug for which developer updates in in one place but not
  in the other place. This would help prevent it!
  */
  public getCodesToDisplay(section: Section): LineItem[] {
    return section.lineItems;
  }

  cancel() {
   
    this.editingCodeIndex = -1;
    this.editingSectionIndex = -1;
    this.newAttribute ={};
    this.help.removeitem("boqolddata");
  }

  submit(boqCodeId) {
    const newBoqCode = this.newAttribute;
  
    if (isNewAttributeICode(newBoqCode)) {
      if (!newBoqCode.rate)
        newBoqCode.rate = 0;
      if(!newBoqCode.quantity)
        newBoqCode.quantity = 0;
     

      const editBoqLink = {
        boqCodeNum: boqCodeId,
        modifiedBy: this.help.getitem("fname"),
        quantityAmt: newBoqCode.quantity,
        rate: newBoqCode.rate,
        projectNum: this.projectDetail.projectNum
      };

      this.spinner.show();
      this.adminService.Editboqlink(this.projectDetail.projectNum, editBoqLink).subscribe(
        (res: any) => {
          if (res.succeeded) {
            this.spinner.hide();
            const olddata1 = this.help.getitem("boqolddata");
            const olddata2 = JSON.parse(olddata1);
            const oldTotal = olddata2.rate * olddata2.quantity;
            const newTotal = newBoqCode.rate * newBoqCode.quantity;
            
            
            let lineItem = this.boqDetail.sections.flatMap(c=>c.lineItems).find(c=>c.id === boqCodeId);
            lineItem.totalCost = newTotal;
            let section = this.boqDetail.sections.find(c=>c.lineItems.includes(lineItem));

            if (oldTotal === 0) {
              this.totalCost = this.totalCost + newTotal;
              section.totalCost = section.totalCost+newTotal;
            } else {
              if (oldTotal > newTotal) {
                this.totalCost = this.totalCost - (oldTotal - newTotal);
                section.totalCost = section.totalCost  - (oldTotal - newTotal);
              } else if (oldTotal < newTotal) {
                this.totalCost = this.totalCost + (newTotal - oldTotal);
                section.totalCost = section.totalCost + (newTotal - oldTotal);
              }
            }

            this.editingCodeIndex = -1;
            this.help.removeitem("boqolddata");
          }
        },
        (err: any) => {
          console.log(err);
          this.spinner.hide();

        }
      );
      return;
    }

    throw new Error(
      "Cannot update database.BOQCode because `this.newAttribute` is an empty object"
    );
  }

  private getBoqBasicDetail(): Promise<void> {
    if (this.isProjectSnapshot) {
      this.refBoq = {
        parentBoqNum: undefined,
        boqName: undefined,
        createdDt: undefined,
        boqClassificationInd: undefined,
        infrastructureNum: undefined,
        milestoneNum: undefined

      };

      return null;
    }
    return this.getInfrastructureBOQ();
  }

  private getInfrastructureBOQ() {
    return this.adminService
      .getInfrastructureBOQ(this.boqLink.proNum, this.boqLink.boqnum)
      .toPromise()
      .then((value) => {
        if (value.succeeded) {
          this.refBoq = value.data;
        } else {
          throw new Error(
            `Fail to getInfrastructureBOQ for ${this.boqLink.boqnum}`
          );
        }
      });
  }


  download() {
    this.spinner.show();
    this.adminService
      .exportInfrastructureBoq(this.boqLink.boqnum, this.boqLink.proNum)
      .subscribe((data) => {
        this.spinner.hide();
        saveAs(data, `${this.boqHeader.boqName}.xlsx`);
      });
  }

  downloadProjectSnapshot() {
    this.spinner.show();
    this.adminService
      .exportSnapshotBoq(this.boqLink.proNum)
      .subscribe((data) => {
        this.spinner.hide();
        saveAs(data, `Project-BOQ-${this.dateNow.toJSON().slice(0, 10)}.xlsx`);
      });
  }

  async uploadFile($event) {
    const form = new FormData();
    form.set("file", $event.target.files[0]);
    form.set("modifiedBy", this.help.authenticationInfo.firstName);
    form.set("projectId", this.boqLink.proNum.toString());
    this.spinner.show();

    this.adminService.updateQuantities(this.boqLink.proNum, this.boqLink.boqnum, form).subscribe(
      (res: any) => {
        this.spinner.hide();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "500px";
        let message = "Quantities updated!";
        if(res.missingCodes && res.missingCodes.length > 0)
        {
          message = message + `<br/>The following codes are missing in Project Boq:-<br/>
          [${res.missingCodes.join(', ')}]<br/>Please add them in Project Boq and reimport`
        }
        const dialogRef = this.dialog.open(AlertBoxComponent, {
          disableClose: true,
          data: {
            res: {
              message: message,
            },
          },
        });
        dialogRef.afterClosed().subscribe(() => {
          window.location.reload();
        });
      },
      (error) => {
        this.spinner.hide();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "500px";
        this.dialog.open(AlertBoxComponent, {
          disableClose: true,
          data: {
            res: {
              message: error,
              isError: true,
            },
          },
        });
      }
    );
  }

  @HostListener("window:scroll")
  handleScroll() {
    this.boqDetailService.handleScroll();
  }

  public getLabelForDropdown(i: BoqDetailDropDown): string {
    if (BoqDetailComponent.isInfrastructureDetailClass(i)) {
      return i.infrastructure_name;
    }
    return "Project Snapshot";
  }

  showTableData(target: MatCheckboxChange) {
    this.showAllData = target.checked;
  }

  public get isProjectSnapshot(): boolean {
    return this.boqLink.infrastructureNum === -1;
  }

  private getBoqCodes(): Promise<BoqResponse> {
    console.log(this.isProjectSnapshot);
    return this.isProjectSnapshot
      ? this.getProjectSnapshot()
      : this.getBoqCodesOfInfrastructure();
  }

  private getProjectSnapshot(): Promise<BoqResponse> {
    return this.boqService.getProjectSnapshotBoq(this.boqLink.proNum).pipe(tap((res) =>{
      this.boqDetail = res;
      this.totalCost = this.boqDetail?.totalCost;
    })).toPromise();
  
  }

 

  private setHasValidDate(projectNum: number): void {
    this.projectService.getProjectInfo(projectNum).subscribe(
      (res: any) => {
        if (res.succeeded) {
          this.hasValidDate = res.data.hasValidDate;
          this.boqLink.proname = res.data.projectName;
          this.boqLink.projectStageInd = res.data.projectStageInd;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  private setClient(projectNum: number): void {
    this.projectService.getProjectClientInfo(projectNum).subscribe(
      (res: any) => {
        if (res.succeeded) {
          this.client = res.data;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
}
