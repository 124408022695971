import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthGaurdGuard } from "../helpers/Auth-gurad/auth-gaurd.guard";
import { Constant } from "../shared/Constant";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";


@Injectable({ providedIn: 'root' })
export class ExcelFileService {

    constructor(private http: HttpClient, private authGuard: AuthGaurdGuard
    ) {
    }

    getExcelSheet<T>(): Observable<any[]> {
        return this.http.get<[]>(Constant.webapi + 'ExcelFile/excel-sheets').pipe();
    }

    getExcelFileSetup<T>(sheetId: number): Observable<any[]> {
        return this.http.get<[]>(Constant.webapi + 'ExcelFile/excel-file-setup/' + sheetId).pipe();
    }

    getAddExcelInput<T>(data: any): Observable<any[]> {
        return this.http.post<[]>(Constant.webapi + 'ExcelFile/add-excel-input', data).pipe();
    }

    updateExcelInput<T>(data: any, id: number): Observable<any[]> {
        return this.http.put<[]>(Constant.webapi + 'ExcelFile/update-excel-input?id=' + id, data).pipe();
    }

    setExcelFile<T>(projectId: number): Observable<any[]> {
        return this.http.get<[]>(Constant.webapi + 'ExcelFile/set-excel-file/' + projectId).pipe();
    }

    uploadTextFolder<T>(formData: any, projectId: string | number): Observable<any[]> {
        return this.http.post<[]>(Constant.webapi + 'ExcelFile/upload-files/' + projectId,
            formData,).pipe();
    }

    getExcelUserInput<T>(projectId: number, sheetName: string): Observable<any[]> {
        return this.http.get<[]>(Constant.webapi +
            `ExcelFile/excel-user-input/${projectId}?sheetName=${sheetName}`).pipe();
    }

    getExcelUserInputByZone<T>(projectId: number, sheetName: string, zone: string): Observable<any[]> {
        return this.http.get<[]>(Constant.webapi +
            `ExcelFile/excel-user-input-zone/${projectId}?sheetName=${sheetName}&zone=${zone}`).pipe();
    }

    getExcelUserInputByOption<T>(projectId: number, sheetName: string, zone: string): Observable<any[]> {
        return this.http.get<[]>(Constant.webapi +
            `ExcelFile/excel-user-all-option/${projectId}?sheetName=${sheetName}&zone=${zone}`).pipe();
    }

    getExcelUserInputAllZone<T>(projectId: number, sheetName: string): Observable<any[]> {
        return this.http.get<[]>(Constant.webapi +
            `ExcelFile/excel-user-all-zone/${projectId}?sheetName=${sheetName}`).pipe();
    }

    upsertExcelUserInput<T>(data: any): Observable<any[]> {
        return this.http.post<[]>(Constant.webapi + `ExcelFile/upsert-excel-user-input/${this.authGuard.projectId}`, data).pipe();
    }

    exportPvfFile(projectId: string) {
        return this.http.get(Constant.webapi + 'ExcelFile/macro-export-pvf/' + projectId, {
            //responseType: 'blob' 
            //reportProgress: true,
            observe: 'response',
            responseType: 'blob' as 'json',
            //params: 'HttpParams'    
        });
    }

    // macroGenerateBoq<T>(projectId: string): Observable<T> {
    //     return this.http.get<T>(Constant.webapi + 'ExcelFile/macro-generate-boq?projectId=' + projectId).pipe();
    // }

    macroGenerateBoq(projectId: string) {
        return this.http.get(Constant.webapi + 'ExcelFile/macro-generate-boq/' + projectId, {
            //responseType: 'blob' 
            //reportProgress: true,
            observe: 'response',
            responseType: 'blob' as 'json',
            //params: 'HttpParams'    
        });
    }

    macroGenerateBoqInfra(projectId: string | number) {
        return this.http.get(Constant.webapi + 'ExcelFile/macro-generate-boq-infra/' + projectId);
    }


    getStringData<T>(): Observable<T> {
        return this.http.get<T>('assets/data/design-strings.json').pipe();
    }

    getTemplateCode<T>(code: string): Observable<any[]> {
        return this.http.get<[]>(Constant.webapi + `ExcelFile/excel-template-code/${this.authGuard.projectId}?code=${code}`).pipe();
    }

    addPayItem<T>(data: any): Observable<any[]> {
        return this.http.post<[]>(Constant.webapi + `ExcelFile/excel-add-payitem/${this.authGuard.projectId}`, data).pipe(debounceTime(300), distinctUntilChanged());
    }

    updatePayItem<T>(data: any): Observable<any[]> {
        return this.http.put<[]>(Constant.webapi + `ExcelFile/excel-update-payitem/${this.authGuard.projectId}`, data).pipe();
    }

    deletePayItem(data: any): Observable<any> {
        return this.http.put(Constant.webapi + `ExcelFile/excel-delete-payitem/${this.authGuard.projectId}`, data).pipe();
    }

    getPayItems<T>(projectId: number): Observable<any[]> {
        return this.http.get<[]>(Constant.webapi + `ExcelFile/excel-pay-item/${projectId}`).pipe();
    }
    
    /** Manhole Calls **/

    getManholes<T>(projectId: number): Observable<any[]> {
        return this.http.get<any[]>(`${Constant.webapi}ExcelFile/excel-manhole/${projectId}`).pipe();
    }

    addManhole<T>(data: any): Observable<any[]> {
        return this.http.post<any[]>(`${Constant.webapi}ExcelFile/excel-add-manhole/${this.authGuard.projectId}`, data)
            .pipe(debounceTime(300), distinctUntilChanged());
    }

    updateManhole<T>(data: any): Observable<any[]> {
        return this.http.put<any[]>(`${Constant.webapi}ExcelFile/excel-update-manhole/${this.authGuard.projectId}`, data).pipe();
    }

    deleteManhole(data: any): Observable<any> {
        return this.http.put(`${Constant.webapi}ExcelFile/excel-delete-manhole/${this.authGuard.projectId}`, data).pipe();
    }

    /**  Pipe Calls **/

    getPipes<T>(projectId: number): Observable<any[]> {
        return this.http.get<any[]>(`${Constant.webapi}ExcelFile/excel-pipe/${projectId}`).pipe();
    }

    addPipe<T>(data: any): Observable<any[]> {
        return this.http.post<any[]>(`${Constant.webapi}ExcelFile/excel-add-pipe/${this.authGuard.projectId}`, data)
            .pipe(debounceTime(300), distinctUntilChanged());
    }

    updatePipe<T>(data: any): Observable<any[]> {
        return this.http.put<any[]>(`${Constant.webapi}ExcelFile/excel-update-pipe/${this.authGuard.projectId}`, data).pipe();
    }

    deletePipe(data: any): Observable<any> {
        return this.http.put(`${Constant.webapi}ExcelFile/excel-delete-pipe/${this.authGuard.projectId}`, data).pipe();
    }

    // this.http.post('http://localhost:5000/api/ExcelFile/upload-files?folder=' + folderName, 
    // formData, {reportProgress: true, observe: 'events'})
}